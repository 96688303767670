<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="saveForm" method="post" id="tags-form">
          <v-select
            label="Tags"
            v-model="fields.tags"
            :items="tags"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            :clearable="true"
            background-color="white"
            :error="errors.hasOwnProperty('tags')"
            :error-messages="errors['tags']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div v-if="errorMessage" class="red--text lighten-4">
          <v-icon small class="red--text mr-2">mdi-alert-circle</v-icon
          >{{ errorMessage }}
        </div>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="tagsloading"
          form="tags-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      tagsloading: false,
      isEditing: false,
      errors: {},
      tags: [],
      fields: {
        tags: [],
      },
      errorMessage: null,
    };
  },

  methods: {
    openForm(product = null) {
      this.dialog = true;
      this.product = product;

      // if (product.tags) {
      this.fields.tags = product.tags.map((c) => c.id);

      const appId = this.$route.params.id;
      const productId = product.id;

      this.loading = true;

      this.$store
        .dispatch("eow/productsStore/loadTagsForProduct", {
          appId,
          productId,
        })
        .then((res) => {
          this.loading = false;
          this.tags = res.data.tags;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });

      //  }
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};

      this.fields.tags = [];
      this.errorMessage = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    saveForm: function () {
      const appId = this.$route.params.id;

      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        fields: this.fields,
        isEditing: this.isEditing,
        productId: this.product.id,
      };

      this.$store
        .dispatch("eow/productsStore/saveProductTags", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;

          if (this.errors) {
            this.errorMessage =
              "Please ensure all fields are completed correctly";

            this.$refs.formWrapper.scrollTop = 0;
          }
        });
    },
  },
};
</script>
